import { PropsWithChildren } from "react";
import { Container } from "./styles";

interface Props {

}
const Label: React.FC<PropsWithChildren<Props>> = ({ children }) => {
    return <Container>
        {children}
    </Container>
}

export default Label;