import { store } from ".."
import api from "../../providers/api"
import { fileUploadSetPercentage } from "./slice"
import { IFormCreateRequest, UploadingAttachment } from "./types"

export const serviceOccurrenceCreateRequest = (data: IFormCreateRequest) => {
    return api.post('occurrencerequest/?_lang=pt-BR', data)
}

export const fileUploadService = (upload: UploadingAttachment, data: FormData) => {
    return api.post('occurrencerequest/upload/?_lang=pt-BR', data, {
        
        onUploadProgress: (event) => {
            let progress: number = Math.round(
                (event.loaded * 100) / event.total
            );

            store.dispatch(fileUploadSetPercentage({
                ...upload,
                percentage: progress
            }))
        },
    })
}