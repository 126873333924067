import React, { PropsWithChildren } from 'react'
import { Container } from './styles';

interface Props {

}

const Row: React.FC<PropsWithChildren<Props>> = ({children}) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default Row;