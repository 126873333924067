import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from '@redux-saga/core'
import rootSaga from './sagas'
import formSlice from './form/slice'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    form: formSlice
  },
  middleware: getDefaultMiddleware => [...getDefaultMiddleware({ thunk: false, serialableCheck: false}), sagaMiddleware]
})

sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type IApplicationState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type IApplicationDispatch = typeof store.dispatch