import React from 'react'
import { Container, Content, Logo, Title } from './styles';
import ImgLogo from '../../assets/porto.png';

export const Header: React.FC = (props) => {
  return (
    <Container>
        <Content>
            <Logo src={ImgLogo} alt={'Porto Seguros'} />

            <Title>Formulário de Registro de Ocorrências</Title>
        </Content>
    </Container>
  )
}

export default Header;