import React from "react";
import { Provider } from "react-redux";
import Form from "./components/Form";
import Header from "./components/Header";
import { store } from "./store";
import "react-datepicker/dist/react-datepicker.css";

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Header />
        <Form />
      </Provider>
    </React.Fragment>
  );
}

export default App;
