import styled from "styled-components";

interface Props {
    size?: 1|2|3|4;
}

export const Container = styled.div<Props>`
    display: flex;
    padding: 0 15px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
    min-height: 60px;
`;