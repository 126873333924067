import React, { PropsWithChildren } from "react";
import { Container } from "./styles";

interface Props {

}

const ErrorMessage: React.FC<PropsWithChildren<Props>> = ({children}) => {
    return <Container>
        {children}
    </Container>
}


export default ErrorMessage;