import styled from "styled-components";

export const Container = styled.header`
    background: ${props => props.theme.colors.background};
    height: 72px;
    display: flex;
    position: fixed;
    width: 100%;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    justify-content: center;
    z-index: 1;
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    max-width: 1200px;
    align-items: center;
    padding: 0 15px;
`;

export const Logo = styled.img`
    margin-right: 20px;
`;

export const Title = styled.h1`
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 0;
    padding: 0;
`;