import styled from "styled-components";

const breakPoints = [
    {
        name: "xs",
        min: 0,
    },
    {
        name: "sm",
        min: 576,
    },
    {
        name: "md",
        min: 768,
    },
    {
        name: "lg",
        min: 992,
    },
    {
        name: "xl",
        min: 1200,
    }
]

const columns = [1,2,3,4,5,6,7,8,9,10,11,12]

export const Container = styled.div`
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(12, 1fr);

    .col-{
        grid-column-end: span 12;
    }

    ${breakPoints.map(breakPoint => `
        @media (min-width: ${breakPoint.min}px) {
            ${columns.map(column => `
                .col-${breakPoint.name}-${column} {
                    grid-column-end: span ${column};
                }
            `)}
        }
    `)}
}
`;