import React, { PropsWithChildren } from 'react'
import { Container } from './styles';

interface Props {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    className?: string;
}

const Col: React.FC<PropsWithChildren<Props>> = ({children, className, xs, sm, md, lg, xl}) => {
    xs = xs ? xs : 12;
    sm = sm ? sm : xs;
    md = md ? md : sm;
    lg = lg ? lg : md;
    xl = xl ? xl : lg;
    return (
        <Container className={`col-xs-${xs} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`}>
            {children}
        </Container>
    )
}

export default Col;