import React, { FormEvent, PropsWithChildren } from "react";
import { Container, ContainerDatePicker, ContainerPhone, ContainerSelect, ContainerTextArea, ContainerTimePicker, ErrorMessage } from "./styles";

type Props = {
    error?: ISystemError;
    type?: 'input'|'select'|'date'|'time'|'textarea'|"phone"; 
    options?: [string, string][],
    disabled?: boolean;
    onChange?: (e: FormEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>) => void;
    onChangePhone?: (e: string) => void;
    onChangeDate?: (date: Date) => void;
    value?: string;
    date?: Date;
}

const Input: React.FC<PropsWithChildren<Props>> = ({ children, error, type = 'input', disabled = false, options, onChange, onChangeDate, onChangePhone, value, date }) => {
    return <React.Fragment>
        {type === 'input' && <Container 
                                disabled={disabled} 
                                error={error} 
                                onChange={onChange}
                                value={value} />}

        {type === 'textarea' && <ContainerTextArea
                                disabled={disabled} 
                                error={error}
                                defaultValue={value} 
                                onChange={onChange} />}

        {type === 'select' && <ContainerSelect 
                                disabled={disabled} 
                                error={error} 
                                onChange={onChange}
                                value={value}>
            <option value="">Selecionar opção</option>
            {options?.map(option => <option key={`macro-place-option-${option[0]}`} value={String(option[0])}>{option[1]}</option>)}    
        </ContainerSelect>}

        {type === 'date' && <ContainerDatePicker 
                                disabled={disabled} 
                                onChange={(newDate) => {
                                    if(onChangeDate && newDate instanceof Date) onChangeDate(newDate)
                                }}
                                selected={date} />}

        {type === 'phone' && <ContainerPhone 
                                disabled={disabled} 
                                onChange={onChangePhone}
                                value={value} />}

        {type === 'time' && <ContainerTimePicker 
                                disabled={disabled} 
                                onChange={(newDate) => {
                                    if(onChangeDate && newDate instanceof Date) onChangeDate(newDate)
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                dateFormat={'H:mm'}
                                selected={date} />}
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </React.Fragment>
}

export default Input;