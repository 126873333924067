import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    min-height: 50px;
    background-color: #ffa099;
    border: 1px solid #f74d40;
    color: #f74d40;
    padding: 20px;
    border-radius: 8px;
`;