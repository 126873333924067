import ReactDatePicker, {registerLocale} from "react-datepicker";
import styled from "styled-components";
import pt from 'date-fns/locale/pt-BR';
import PhoneInput from 'react-phone-number-input/input'
registerLocale('pt-BR', pt)
interface InputContainer {
    error?: ISystemError
}

export const Container = styled.input<InputContainer>`
    font-size: 12px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    border: 1px solid ${props => props.error ? '#eb5234' :  props.theme.colors.primary};
    border-radius: 10px;
    outline: none;
`;

export const ContainerTextArea = styled.textarea<InputContainer>`
    font-size: 12px;
    width: 100%;
    height: 200px;
    padding: 15px;
    border: 1px solid ${props => props.error ? '#eb5234' :  props.theme.colors.primary};
    border-radius: 10px;
    outline: none;
`;

export const ContainerSelect = styled.select<InputContainer>`
    font-size: 12px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    border: 1px solid ${props => props.error ? '#eb5234' :  props.theme.colors.primary};
    border-radius: 10px;
    outline: none;
`;

export const ContainerPhone = styled(PhoneInput).attrs(props => ({
    defaultCountry: 'BR',
    international: false
}))`
    font-size: 12px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    border: 1px solid ${props => props.error ? '#eb5234' :  props.theme.colors.primary};
    border-radius: 10px;
    outline: none;
`;

export const ContainerDatePicker = styled(ReactDatePicker).attrs(props => ({
    dateCaption: "Data",
    locale: "pt-BR",
    dateFormat: "dd/MM/yyyy"
}))`
    font-size: 12px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    border-radius: 10px;
    outline: none;
    border: 1px solid ${props =>  props.theme.colors.primary};
`;

export const ContainerTimePicker = styled(ReactDatePicker).attrs(props => ({
    showTimeSelect: true,
    timeIntervals:15,
    showTimeSelectOnly: true,
    timeFormat: "H:mm",
    timeCaption: "Hora"
}))`
    font-size: 12px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    border-radius: 10px;
    outline: none;
    border: 1px solid ${props =>  props.theme.colors.primary};
`;

export const ErrorMessage = styled.small`
    color: #eb5234;
    font-size: 12px;
`;