import React from "react";
import { useDispatch } from "react-redux";
import { sliceFormNewOccurrence } from "../../store/form/slice";
import Button from "../Button";
import Col from "../Col";
import Row from "../Row";
import { Container, Content } from "./styles";

const SentMessage: React.FC = () => {
    const dispatch = useDispatch();

    return (
        <Container>
            <Content>
                <Row>
                    <Col>
                        <h1>Solicitação recebida</h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p>Futuras atualizações poderão ser informadas por e-mail</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Button onClick={() => {
                            dispatch(sliceFormNewOccurrence())
                        }}>Registrar nova ocorrência</Button>
                    </Col>
                </Row>
            </Content>
        </Container>
    )
}

export default SentMessage;