import React, { PropsWithChildren } from 'react'
import { Container } from './styles';

interface Props {
  onClick: () => void;
  disabled?: boolean;
  small?: boolean;
  danger?: boolean;
}

export const Button: React.FC<PropsWithChildren<Props>> = ({children, onClick, disabled = false, small, danger}) => {
  return (
    <Container disabled={disabled} onClick={onClick} className={`${small ? 'small' : ''} ${danger ? 'danger' : ''}`}>
        {children}
    </Container>
  )
}

export default Button;