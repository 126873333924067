import { fileUploadService, serviceOccurrenceCreateRequest } from "./services";
import { takeEvery, all, put } from "redux-saga/effects"
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { IFormCreateResponse, IFormData, UploadRequest, UploadResponse } from "./types";
import { fileUpload, fileUploadComplete, fileUploadError, sliceFormCreateOccurrenceFailure, sliceFormCreateOccurrenceRequest, sliceFormCreateOccurrenceSuccess } from "./slice";

function* sagaOccurrenceCreateRequest(action: PayloadAction<IFormData>){
    try {
        if(action.payload.macroPlace === undefined || action.payload.dateOccurrence === undefined) {
            yield put(sliceFormCreateOccurrenceFailure({
                message: "Todos os campos são obrigatórios"
            }))
        }
        else {
            const createOccurrenceResponse: AxiosResponse<IFormCreateResponse> =  yield serviceOccurrenceCreateRequest({
                ...action.payload,
                macroPlace: action.payload.macroPlace || 0,
                tipology: action.payload.tipology || 0,
                dateOccurrence: action.payload.dateOccurrence || new Date().toISOString()
            });
    
            if(createOccurrenceResponse.data.status) {
                yield put(sliceFormCreateOccurrenceSuccess())
            }
            else {
                yield put(sliceFormCreateOccurrenceFailure({
                    message: createOccurrenceResponse.data.message
                }))
            }
        }

    }
    catch(err: any){
        console.log(err);
        
        yield put(sliceFormCreateOccurrenceFailure({
            message: err.response.data.message || "Erro ao tentar solicitar registro de ocorrência"
        }))
    }
}

function* watchSagaOccurrenceCreateRequest(){
    yield takeEvery(sliceFormCreateOccurrenceRequest.type, sagaOccurrenceCreateRequest);
}

function* fileUploadSaga(action: PayloadAction<UploadRequest>) {
    try {
        const uploadResponse: AxiosResponse<UploadResponse> = yield fileUploadService(
            action.payload.upload,
            action.payload.data
        );

        if (uploadResponse.data.status) {
            yield put(fileUploadComplete({
                ...action.payload.upload,
                path: uploadResponse.data.data.path
            }))

        }

        
    }
    catch (error: any) {
        yield put(fileUploadError({
            ...action.payload.upload,
            error: {
                message: error.response.data.message || 'Error trying to upload file'
            }
        }));
    }
}

function* watchFileUploadSaga() {
    yield takeEvery(fileUpload.toString(), fileUploadSaga);
}

export default all([
    watchSagaOccurrenceCreateRequest(),
    watchFileUploadSaga()
])