import styled from "styled-components";

export const Container = styled.button`
    height: 30px;
    padding: 0 15px;
    background: ${props => props.theme.colors.primary};
    color: #FFF;
    font-weight: bold;
    border: 0;
    border-radius: 4px;
    font-size: 12px;
    width: fit-content;

    &.small {
        height: 20px;
        font-size: 10px;
    }

    &.danger {
        background-color: #ab1d13;
    }
`;