import styled from "styled-components";

export const Container = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 72px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
`;

export const DropZoneWrapper = styled.div`
    width: 100%;
    display: flex;
    min-height: 120px;
    border: 2px dashed ${({theme}) => theme.colors.primary};
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    p {
        font-size: 20px;
        color: #ccc;
    }
`;