import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFormData, IFormState, UploadingAttachment } from "./types";

export const initialState: IFormState = {
    isSaving: false,
    data: {
        name: "",
        email: "",
        register: "",
        department: "",
        locality: "",
        floor: "",
        emailSupervisor: "",
        description: "",
        place: "",
        departmentPlace: "",
        phone: "",
        dateOccurrence: new Date().toISOString(),
        dateOccurrenceEnd: new Date().toISOString()
    },
    uploads: []
}

export const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        sliceFormChangeData: (state, action: PayloadAction<IFormData>) => {
            state.data = action.payload;
        },
        sliceFormCreateOccurrenceRequest: (state, action: PayloadAction<IFormData>) => {
            state.isSaving = true;
            state.error = undefined;
        },
        sliceFormCreateOccurrenceSuccess: (state) => {
            state.isSaving = false;
            state.isSent = true;
            // state.data = initialState.data
            state.error = undefined;
        },
        sliceFormCreateOccurrenceFailure: (state, action: PayloadAction<ISystemError>) => {
            state.isSaving = false;
            state.error = action.payload;
        },
        sliceFormNewOccurrence: (state) => {
            state.isSent = false;
        },
        fileUpload: (state, action: PayloadAction<{
            upload: UploadingAttachment;
            data: FormData
        }>) => {
            state.uploads = [...state.uploads, action.payload.upload]
        },
        fileUploadSetPercentage(state, action: PayloadAction<UploadingAttachment>) {
            state.uploads = state.uploads.map(upload => {
                if (upload.id !== action.payload.id) return upload;

                return {
                    ...upload,
                    percentage: action.payload.percentage
                }
            })
        },
        fileUploadError(state, action: PayloadAction<UploadingAttachment>) {
            state.uploads = state.uploads.map(upload => {
                if (upload.id !== action.payload.id) return upload;

                return {
                    ...upload,
                    error: action.payload.error
                }
            })
        },
        fileUploadComplete(state, action: PayloadAction<UploadingAttachment>) {
          state.uploads = state.uploads.filter(upload => upload.id !== action.payload.id);

          const upload = action.payload;

          state.data.attachment = [...state.data.attachment || [], {
            fileName: upload.fileName,
            path: upload.path,
            size: upload.size
          }]
        },
        removeFile(state, action: PayloadAction<string>) {
            state.uploads = state.uploads.filter(upload => upload.id !== action.payload);
        },
        removeFileUploaded(state, action: PayloadAction<number>) {
            state.data.attachment = state.data.attachment?.filter((upload, idx) => idx !== action.payload) || [];
        }
    }
})

export const {
    sliceFormChangeData,
    sliceFormCreateOccurrenceRequest,
    sliceFormCreateOccurrenceSuccess,
    sliceFormCreateOccurrenceFailure,
    sliceFormNewOccurrence,
    fileUpload,
    fileUploadSetPercentage,
    fileUploadError,
    fileUploadComplete,
    removeFile,
    removeFileUploaded
} = formSlice.actions;

export default formSlice.reducer;