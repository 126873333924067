import React, { PropsWithChildren, useCallback, useEffect } from "react";
import Button from "../Button";
import Col from "../Col";
import Input from "../Input";
import Label from "../Label";
import Row from "../Row";
import { Container, Content, DropZoneWrapper } from "./styles";
import { useDispatch, useSelector } from "react-redux"
import { IApplicationState } from "../../store";
import { fileUpload, removeFile, removeFileUploaded, sliceFormChangeData, sliceFormCreateOccurrenceRequest } from "../../store/form/slice";
import ErrorMessage from "../ErrorMessage";
import SentMessage from "../SentMessage";
import { useDropzone, DropEvent } from 'react-dropzone'
import { v4 } from 'uuid';
import { Attachment } from "../../store/form/types";

interface Props {

}

const googleRecaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || "";

const Form: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const formState = useSelector((state: IApplicationState) => state.form)


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);

      acceptedFiles.forEach(file => {
        const formData = new FormData();
        formData.append('file', file)

        dispatch(fileUpload({
          upload: {
            id: v4(),
            fileName: file.name,
            size: file.size,
            path: "",
            percentage: 0,
          },
          data: formData
        }))

      })
    }
  })

  const isDisabled = formState.isSaving;

  const dispatch = useDispatch();

  useEffect(() => {
    if (grecaptcha) {
      grecaptcha.enterprise.ready(function () {
        grecaptcha.enterprise.execute(googleRecaptchaKey, { action: 'login' }).then(function (token) {

        });
      });
    }
  }, [dispatch])


  if (formState.isSent) return <SentMessage />

  return (
    <Container>
      <Content>
        <Row>
          <Col>
            <h1>Solicitante</h1>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Label>Nome</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.name}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  name: e.currentTarget.value
                }))
              }} />
          </Col>

          <Col md={4}>
            <Label>Matrícula</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.register}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  register: e.currentTarget.value
                }))
              }} />
          </Col>

          <Col md={4}>
            <Label>E-mail</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.email}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  email: e.currentTarget.value
                }))
              }} />
          </Col>

          <Col md={4}>
            <Label>Telefone</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.phone}
              type="phone"
              onChangePhone={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  phone: e
                }))
              }} />
          </Col>

          <Col md={4}>
            <Label>Departamento</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.department}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  department: e.currentTarget.value
                }))
              }} />
          </Col>

          <Col md={4}>
            <Label>Prédio/Sucursal</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.locality}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  locality: e.currentTarget.value
                }))
              }} />
          </Col>

          <Col md={4}>
            <Label>Andar</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.floor}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  floor: e.currentTarget.value
                }))
              }} />
          </Col>
        </Row>

        <Row>
          <Col>
            <h1>Gestor Responsável</h1>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Label>E-mail</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.emailSupervisor}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  emailSupervisor: e.currentTarget.value
                }))
              }} />
          </Col>
        </Row>

        <Row>
          <Col>
            <h1>Ocorrência</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <Label>Descrição</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.description}
              type="textarea"
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  description: e.currentTarget.value
                }))
              }} />
          </Col>

          <Col sm={6} md={4}>
            <Label>Local</Label>
            <Input
              disabled={isDisabled}
              value={String(formState.data.macroPlace)}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  macroPlace: parseInt(e.currentTarget.value) || undefined
                }))
              }}
              type="select"
              options={[
                ["2", 'Matriz'],
                ["4", 'Porto Atendimento'],
                ["1", 'Surcusal'],
                ["3", 'CAPS'],
                ["67", 'Carro Fácil'],
                ["187", 'Consórcio'],
                ["207", 'Renova'],
                ["7", 'Salvados'],
                ["227", 'Azul'],
              ]}
            />
          </Col>

          <Col sm={6} md={4}>
            <Label>Prédio</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.place}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  place: e.currentTarget.value
                }))
              }} />
          </Col>

          <Col sm={6} md={4}>
            <Label>Local do ocorrido</Label>
            <Input
              disabled={isDisabled}
              value={formState.data.departmentPlace}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  departmentPlace: e.currentTarget.value
                }))
              }} />
          </Col>

          <Col sm={6} md={2}>
            <Label>Data Início</Label>
            <Input
              disabled={isDisabled}
              type={'date'}
              date={new Date(formState.data.dateOccurrence || Date.now())}
              onChangeDate={(date) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  dateOccurrence: date.toISOString()
                }))
              }} />
          </Col>

          <Col sm={6} md={2}>
            <Label>Hora Início</Label>
            <Input
              disabled={isDisabled}
              type={'time'}
              date={new Date(formState.data.dateOccurrence || Date.now())}
              onChangeDate={(date) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  dateOccurrence: date.toISOString()
                }))
              }} />
          </Col>

          <Col sm={6} md={2}>
            <Label>Data Fim</Label>
            <Input
              disabled={isDisabled}
              type={'date'}
              date={new Date(formState.data.dateOccurrenceEnd || Date.now())}
              onChangeDate={(date) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  dateOccurrenceEnd: date.toISOString()
                }))
              }} />
          </Col>

          <Col sm={6} md={2}>
            <Label>Hora Fim</Label>
            <Input
              disabled={isDisabled}
              type={'time'}
              date={new Date(formState.data.dateOccurrenceEnd || Date.now())}
              onChangeDate={(date) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  dateOccurrenceEnd: date.toISOString()
                }))
              }} />
          </Col>

          <Col md={4}>
            <Label>Tipologia</Label>
            <Input
              disabled={isDisabled}
              value={String(formState.data.tipology)}
              type={'select'}
              options={[["9", "Acidente de trabalho"], ["18", "Acompanhamento"], ["21", "Análise especial"], ["30", "Análise de risco"], ["16", "Assédio sexual"], ["15", "Assédio moral"], ["5", "Avaria a veículo"], ["23", "Canal de denúncia"], ["31", "Desaparecimento"], ["2", "Desvio de Conduta"], ["7", "Conferência"], ["6", "Dano ao patrimônio"], ["13", "Fraude"], ["1", "Furto"], ["12", "Intrusão"], ["8", "Ofício"], ["10", "Perda"], ["20", "Preventivo"], ["22", "Resilição"], ["4", "Roubo"], ["14", "Telemetria"]]}
              onChange={(e) => {
                dispatch(sliceFormChangeData({
                  ...formState.data,
                  tipology: parseInt(e.currentTarget.value)
                }))
              }} />
          </Col>
        </Row>

        <Row>
          <Col>
            <h1>Adicionar anexos (opcional)</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <DropZoneWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Solte os arquivos aqui...</p> :
                  <p>Para enviar arquivos, arraste e solte eles aqui, ou clique para procurar</p>
              }
            </DropZoneWrapper>
          </Col>
          
        </Row>

        {formState.uploads.length > 0 && <Row>
          <Col>
            <h1>Enviando arquivos</h1>
            <ul>
              {formState.uploads.map(upload => <li key={`upload-${upload.id}`}>{upload.fileName}{upload.error ? ` - Erro ao enviar arquivo: ${upload.error.message}` : ` (${upload.percentage || 0}%)`} <Button small danger onClick={() => {
                dispatch(removeFile(upload.id))
              }}>Remover</Button></li>)}
            </ul>
          </Col>
        </Row>}

        {(formState.data.attachment?.length || 0) > 0 && <Row>
          <Col>
            <h1>Arquivos enviados</h1>
            <ul>
              {formState.data.attachment?.map((upload, idx) => <li key={`upload-${upload.path}`}>{upload.fileName} <Button small danger onClick={() => {
                dispatch(removeFileUploaded(idx))
              }}>Remover</Button></li>)}
            </ul>
          </Col>
        </Row>}

        {formState.error && <Row>
          <Col>
            <ErrorMessage>{formState.error.message}</ErrorMessage>
          </Col>
        </Row>}

        <Row>
          <Col>
            <Button disabled={formState.isSaving || formState.uploads.filter(upload => !upload.error).length > 0} onClick={() => {
              dispatch(sliceFormCreateOccurrenceRequest(formState.data))
            }}>{formState.isSaving ? 'Solicitando...' : 'Solicitar registro de ocorrência'}</Button>
          </Col>
        </Row>
      </Content>
    </Container>
  )
}

export default Form;