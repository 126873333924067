import { createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Open Sans',Sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        color: #000;
        font-size: 14px;
    }

    body, html {
        background-color: #FFF;
        overflow-x: hidden;
    }

    h1 {
        margin-bottom: 0;
        margin-top: 30px;
        font-size: 18px;
        font-weight: 700;
        color: ${props => props.theme.colors.primary};
    }

    ::-webkit-scrollbar {
    width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #555;
    } 
`;